.sale-box[data-v-06e393ad] {
  background-color: #f3f3f3;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
}
.sale-item[data-v-06e393ad] {
  margin-bottom: 30px;
  border: 1px solid #e5e5e5;
  height: 140px;
  line-height: 140px;
  text-align: center;
}
.sale-item .num[data-v-06e393ad] {
    margin-left: 10px;
}
.panel-container[data-v-06e393ad] {
  border: 1px solid #e5e5e5;
  border-right: none;
}
.panel-box[data-v-06e393ad] {
  width: 100%;
  height: 122px;
  border-right: 1px solid #e5e5e5;
  text-align: center;
  padding-top: 30px;
}
.panel-box .number[data-v-06e393ad] {
    font-size: 25px;
    font-weight: 700;
    margin-top: 10px;
}
.box[data-v-06e393ad] {
  background-color: #fff;
}
.wait-box[data-v-06e393ad] {
  height: 130px;
  margin: 0px 0 14px 0;
  border: 1px solid #e5e5e5;
  border-top: none;
  font-size: 16px;
}
.wait-box .el-col[data-v-06e393ad] {
    text-align: center;
}
.wait-box .wrap[data-v-06e393ad] {
    margin-top: 50px;
}
.wait-box .wrap .div .text[data-v-06e393ad] {
      white-space: nowrap;
}
.wait-box .wrap .div[data-v-06e393ad]:hover {
      cursor: pointer;
}
.wait-box .number[data-v-06e393ad] {
    margin-top: 5px;
    font-size: 20px;
    color: #1890ff;
}
.title-box[data-v-06e393ad] {
  height: 56px;
  line-height: 56px;
  background-color: #f3f3f3;
}
.title-box .title[data-v-06e393ad] {
    margin-left: 20px;
}
@media (max-width: 768px) {
.sale-box[data-v-06e393ad] {
    padding: 0 5px;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    white-space: nowrap;
}
.sale-item[data-v-06e393ad] {
    height: 60px;
    line-height: 60px;
}
.title-box[data-v-06e393ad] {
    height: 30px;
    line-height: 30px;
}
.wait-box[data-v-06e393ad] {
    font-size: 14px;
    height: 100px;
}
.wait-box .wrap[data-v-06e393ad] {
      margin-top: 30px;
}
.wait-box .number[data-v-06e393ad] {
      font-size: 16px;
}
}
